import React from 'react';
import { Cards } from '../components/card/card';

const Pachete = () => {
  return (
    <div>
      <Cards />
    </div>
  );
};

export default Pachete;
