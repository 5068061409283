import React from 'react';
import './styles.css';
export const Whatsap = () => {
  return (
    <div className="container">
      <a className="link-whatsapp" href="https://wa.me/+40748455547">
        Vorbeste cu echipa noastra pe WhatsApp
      </a>
    </div>
  );
};
